import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { HelmetDatoCms } from "gatsby-source-datocms";

import PageBuilder from "../components/pagebuilder";
import Faq from "../components/faq";

import { useI18next } from "gatsby-plugin-react-i18next";
// import LogoFalling from "../sections-static/logo-falling";
import SecondaryClouds from "../components/secondary-clouds";

const DynamicPage = ({ data }) => {
  const { language } = useI18next();
  return (
    <Layout>
      {data.page.nodes.map((page) => (
        <main key={page.id} className="page-builder">
          <HelmetDatoCms seo={page.seoMetaTags} />
          <PageBuilder lang={language} data={page.builder} />
          {page?.faqTitle && page?.faqItem?.length ? (
            <section className="support-faq-section infront" id="faq">
              <h1 className="prewrap">{page.faqTitle}</h1>
              <Faq data={page.faqItem} />
            </section>
          ) : (
            <></>
          )}
          <SecondaryClouds />
        </main>
      ))}
    </Layout>
  );
};
export default DynamicPage;

export const query = graphql`
  query DynamicPageQuery($language: String!, $id: String!) {
    page: allDatoCmsDynamicPage(
      filter: { originalId: { eq: $id }, locale: { eq: $language } }
    ) {
      nodes {
        originalId
        id
        title
        slug
        builder {
          ... on DatoCmsImageContent {
            ...ImageContent
          }
          ... on DatoCmsColumnedList {
            ...ColumnedList
          }
          ... on DatoCmsCarousel {
            ...Carousel
          }
          ... on DatoCmsFeatureCarousel {
            ...Features
          }
          ... on DatoCmsNicheSelector {
            ...Niches
          }
          ... on DatoCmsCustomerTestimonial {
            ...Testimonials
          }
          ... on DatoCmsValuesList {
            ...Values
          }
          ... on DatoCmsCallToActionBox {
            ...CTABox
          }
          ... on DatoCmsImageVideo {
            ...ImageVideo
          }
        }
        faqTitle
        faqItem {
          id
          question
          answer
        }
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
      }
    }
  }
`;
